import React, { createContext, useContext, useState, ReactNode, ReactElement } from "react";

// Context의 value에 대한 타입 정의
interface HoverContextType {
  hover: boolean[];
  setHoverIndexTrue: (index: number) => void;
  setHoverFalse: () => void;
}

// Context 생성 시, 기본값을 위한 타입을 명시적으로 지정
const HoverContext = createContext<HoverContextType | undefined>(undefined);

// Custom hook을 통하여 context를 사용. 이 hook은 context가 존재하지 않을 때 오류를 발생시킵니다.
export const useHover = (): HoverContextType => {
  const context = useContext(HoverContext);
  if (!context) {
    throw new Error("useHover must be used within a HoverProvider");
  }
  return context;
};

// HoverProvider의 props 타입 정의
interface HoverProviderProps {
  children: ReactNode;
}

// HoverProvider 컴포넌트 정의
export const HoverProvider: React.FC<HoverProviderProps> = ({ children }): ReactElement | null => {
  const [hover, setHover] = useState<boolean[]>([false, false, false, false, false]);

  const setHoverIndexTrue = (index: number) => {
    setHover(hover.map((item, i) => (i === index ? true : item)));
  };

  const setHoverFalse = () => {
    setHover(hover.map(() => false));
  };

  return <HoverContext.Provider value={{ hover, setHoverIndexTrue, setHoverFalse }}>{children}</HoverContext.Provider>;
};

import React, { Suspense, lazy } from "react";
import { HoverProvider } from "./HoverContext";
// import { useDispatch, useSelector } from "react-redux";
// import { addTodo, RootState } from "./store/todoSlice";
// import List from "./components/List";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import Loading from "./components/loading";

const HomePage = lazy(() => import("./pages/HomePage"));
const SubMain = lazy(() => import("./pages/SubMain"));
const Wanted = lazy(() => import("./pages/Wanted"));
const Code = lazy(() => import("./pages/Code"));

const App: React.FC = () => {
  return (
    <HoverProvider>
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/other-page" element={<SubMain />} />
            <Route path="/wanted" element={<Wanted />} />
            <Route path="/code" element={<Code />} />
          </Routes>
        </Suspense>
      </Router>
    </HoverProvider>
  );
};

export default App;
